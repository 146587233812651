import React from 'react'
import { FaGithub, FaLinkedin, FaInstagram } from 'react-icons/fa'
import styles from './styles/footer.module.css'

export const Footer = () => {
  return (
    <section className={styles.container}>
      <div className={styles.icons}>
        <a href="https://github.com/chris-melvin">
          <FaGithub size="2rem" />
        </a>
        <a href="https://www.linkedin.com/in/chris-melvin/">
          <FaLinkedin size="2rem" />
        </a>
        <a href="https://www.instagram.com/chrismelvinf_/">
          <FaInstagram size="2rem" />
        </a>
      </div>
      <div>
        © {new Date().getFullYear()}, Built with
        {` `}
        <a href="https://www.gatsbyjs.org">Gatsby</a>
      </div>
    </section>
  )
}
